import { HelpOutline } from '@mui/icons-material';
import { Box, Popover } from '@mui/material';
import { useEffect, useId, useState } from 'react';

export const Pop = ({ suggestion, width }) => {
  const id = useId();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const idPop = open ? id : undefined;

  useEffect(() => {
    const handlepop = () => setAnchorEl(null);
    if (anchorEl) {
      window.addEventListener('click', handlepop);
    }
    return () => {
      window.removeEventListener('click', handlepop);
    };
  }, [anchorEl]);

  return (
    <>
      <Box
        aria-describedby={idPop}
        aria-haspopup="true"
        aria-owns={idPop}
        onMouseOver={handleClick}
        onMouseLeave={handleClose}
        sx={{ display: 'flex' }}
      >
        <HelpOutline sx={{ color: '#CFD4EA' }} />
      </Box>
      <Popover
        id={idPop}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
        sx={{ pointerEvents: 'none' }}
      >
        <Box
          sx={{
            maxWidth: width || '200px',
            p: 2,
            background: '#515568',
            color: 'white',
            fontSize: '14px',
            lineHeight: '17px',
          }}
          dangerouslySetInnerHTML={{ __html: suggestion }}
        />
      </Popover>
    </>
  );
};
