export const prices = {
  1: 27480,
  2: 39750,
  3: 55500,
};

export const pricesOld = {
  1: 18320,
  2: 26500,
  3: 37000,
};

export const values = {
  businessType: [
    {
      title: 'Оптовая торговля',
      value: 1,
      base: 1,
      limit: 100,
    },
    {
      title: 'Производство',
      value: 2,
      base: 2,
      limit: 400,
    },
    {
      title: 'Розничная торговля',
      value: 3,
      base: 2,
      limit: 400,
    },
    {
      title: 'Строительство',
      value: 4,
      base: 2,
      limit: 400,
    },
    {
      title: 'Услуги',
      value: 5,
      base: 1,
      limit: 100,
    },
    {
      title: 'Аптека',
      value: 6,
      base: 3,
      limit: 500,
    },
    {
      title: 'Турагентства',
      value: 7,
      base: 3,
      limit: 500,
    },
    {
      title: 'Маркетплейс',
      value: 8,
      base: 3,
      limit: 500,
    },
    {
      title: 'Иное',
      value: 9,
      base: 1,
      limit: 100,
    },
  ],
  orgType: [
    {
      title: 'ИП',
      value: 1,
      coef: 1,
    },
    {
      title: 'ООО',
      value: 2,
      coef: 1.15,
    },
  ],
  snoType: [
    {
      title: 'УСН 6%',
      coef: 1,
      value: 1,
    },
    {
      title: 'УСН доходы минус расходы',
      coef: 1.2,
      value: 2,
    },
    {
      title: 'ОСНО',
      coef: 1.3,
      value: 3,
    },
    {
      title: 'Совмещение (когда есть несколько режимов налогообложения)',
      coef: 1.4,
      value: 4,
    },
  ],
  discount: [
    {
      label: 'Без скидки',
      value: 0,
    },
    {
      label: 'Скидка 15%',
      value: 15,
    },
    {
      label: 'Скидка 10%',
      value: 10,
    },
  ],
  turnover: [
    {
      title: 'До 10 млн. ₽ в месяц',
      value: 1,
      coef: 1,
    },
    {
      title: 'От 10 до 20 млн. ₽ в мес',
      value: 2,
      coef: 1.5,
    },
    {
      title: 'От 20 до 30 млн. ₽ в мес',
      value: 3,
      coef: 2,
    },
    {
      title: 'От 30 до 50 млн. ₽ в мес',
      value: 4,
      coef: 0,
    },
    {
      title: 'От 50 до 80 млн. ₽ в мес',
      value: 5,
      coef: 0,
    },
    {
      title: 'От 80 до 100 млн. ₽ в мес',
      value: 6,
      coef: 0,
    },
    {
      title: 'Свыше 100 млн. ₽ в мес',
      value: 7,
      coef: 0,
    },
  ],
  staff: [
    {
      value: [0, 1],
      coef: 1,
    },
    {
      value: [2, 5],
      coef: 1.05,
    },
    {
      value: [6, 10],
      coef: 1.1,
    },
    {
      value: [11, 15],
      coef: 1.2,
    },
    {
      value: [16, 30],
      coef: 1.25,
    },
    {
      value: [31, 60],
      coef: 1.4,
    },
    {
      value: [61, 90],
      coef: 1.45,
    },
    {
      value: [91, 150],
      coef: 1.5,
    },
    {
      value: [151, +Infinity],
      coef: 0,
    },
  ],
};

export const tarifs = {
  standart: { name: 'Стандарт', coef: 1 },
  expert: { name: 'Эксперт', coef: 1.2 },
  business: { name: 'Бизнес', coef: 1.8 },
  premium: { name: 'Премиум', coef: 3.6 },
};

export const phrasesSales = [
  'У тебя <strong>все получится</strong>!<br />Мы в тебя верим!',
  'Мы <strong>гордимся</strong> тобой!<br />',
  'Ты все делаешь <strong>правильно</strong>!',
  'Всем бывает страшно!',
  '<strong>Ты</strong> сильный, успешный и умный!',
  'Ты <strong>сможешь</strong>, если захочешь!',
  'Ты способен <strong>преодолеть все трудности</strong>!',
  'Хороший результат требует <strong>значительных усилий</strong>!',
  '<strong>Мы тебя любим</strong>, несмотря ни на что!',
  '<strong>Дожми его</strong> - этот клиент точно твой!',
  'Еще немного.<br /><strong>Выставляй счет</strong>, направляй КП и выжми оплату!',
  'Ты <strong>сегодня молодец</strong> - похвали себя и опять звонить!',
  'Ты менеджер Главбух Ассистент -<br /><strong>элита Актиона</strong>!',
  'Помни - все клиенты врут.<br /><strong>Докажи ценность продукта</strong>, чтобы сделать продажу',
  'Не бойся отказа, он лишь<br /><strong>ступень к успеху</strong>.',
  'Твоя настойчивость – это разница между обычным и<br /><strong>великим продавцом</strong>.',
  'Будь <strong>экспертом</strong>,<br />стань авторитетом в глазах клиента.',
  '<strong>Уверенность в себе</strong> и своих силах – залог успешных продаж.',
];

export const phrasesClient = ['Клиент <strong>всегда прав</strong>!'];

export const dicountTypes = (showMarkup) => [
  ['discount', 'Скидка'],
  ...(showMarkup ? [['markup', 'Наценка']] : []),
  ['netto', 'Нетто'],
];
