const tarifs = [
  {
    id: 'standart',
    name: 'Стандарт',
  },
  {
    id: 'expert',
    name: 'Эксперт',
  },
  {
    id: 'business',
    name: 'Бизнес',
  },
  {
    id: 'premium',
    name: 'Премиум',
  },
];

export default tarifs;
