module.exports = {
  'юридические услуги': [
    {
      id: 'legal-consultation-oral',
      name: 'Устная юридическая консультация',
      cost: 2000,
      full: 'Устная юридическая консультация',
      costs: {
        standart: 3000,
        expert: 3000,
        business: 0,
        premium: 0,
      },
    },
    {
      id: 'legal-consultation-written',
      name: 'Письменная юридическая консультация',
      cost: 2000,
      full: 'Письменная юридическая консультация',
      costs: {
        standart: 3000,
        expert: 3000,
        business: 0,
        premium: 0,
      },
    },
    {
      id: 'legal-template-selection',
      name: 'Подбор формы договора, документа',
      cost: 1000,
      full: 'Подбор шаблонной формы договора или документа',
      costs: {
        standart: 1500,
        expert: 0,
        business: 0,
        premium: 0,
      },
    },
    {
      id: 'legal-contract-preparation',
      name: 'Подготовка договора',
      cost: 3000,
      full: 'Подготовка договора на базе стандартного имеющегося с внесением корректировок в основные условия), составление дополнительных соглашений к заключенным договорам и соглашений о расторжении заключенных договоров',
      suggestion:
        'На базе стандартного имеющегося с внесением корректировок в основные условия, а также составление дополнительных соглашений к заключенным договорам и соглашений о расторжении заключенных договоров',
      costs: {
        standart: 4500,
        expert: 4500,
        business: 0,
        premium: 0,
      },
    },
    {
      divider: 'Анализ норм договора',
      ids: ['legal-verbal-conclusion', 'legal-written-conclusion'],
    },
    {
      id: 'legal-verbal-conclusion',
      name: 'С устным заключением',
      cost: 3000,
      full: 'Анализ норм договора с устным заключением',
      costs: {
        standart: 4500,
        expert: 4500,
        business: 0,
        premium: 0,
      },
    },
    {
      id: 'legal-written-conclusion',
      name: 'С письменным заключением',
      cost: 3000,
      full: 'Анализ норм договора с письменным заключением',
      costs: {
        standart: 4500,
        expert: 4500,
        business: 0,
        premium: 0,
      },
    },
    {
      id: 'legal-disagreement-protocol',
      name: 'Протокол разногласий к договору',
      cost: 3000,
      full: 'Составление Протокола разногласий к договору',
      costs: {
        standart: 4500,
        expert: 4500,
        business: 0,
        premium: 0,
      },
    },
    {
      divider: '',
    },
    {
      id: 'legal-registration-branches',
      name: 'Гос-ная рег-я филиалов, представительств',
      cost: 10000,
      full: 'Подготовка документов для государственной регистрации филиалов, представительств (без подачи документов в налоговую)',
      suggestion: 'Подготовка документов без подачи документов в налоговую',
      costs: {
        standart: 15000,
        expert: 15000,
        business: 15000,
        premium: 0,
      },
    },
    {
      id: 'legal-single-participant-resolution',
      name: 'Решения единственного участника ООО',
      cost: 3000,
      full: 'Оформление Решений единственного участника ООО (не связанной с регистрацией ООО)',
      suggestion: 'Оформление не связанной с регистрацией ООО',
      costs: {
        standart: 4500,
        expert: 4500,
        business: 0,
        premium: 0,
      },
    },
    {
      id: 'legal-assembly-protocols',
      name: 'Протоколы собраний участников ООО',
      cost: 3000,
      full: 'Оформление Протоколов общего собрания участников ООО (не связанной с регистрацией ООО)',
      suggestion: 'Оформление не связанной с регистрацией ООО',
      costs: {
        standart: 4500,
        expert: 4500,
        business: 0,
        premium: 0,
      },
    },
    {
      id: 'legal-okved-assistance',
      name: 'ОКВЭД',
      cost: 3000,
      full: 'Помощь в подборе ОКВЭД - до 5 штук (без подачи документов в налоговую)',
      suggestion: 'Помощь в подборе без подачи документов в налоговую',
      costs: {
        standart: 4500,
        expert: 4500,
        business: 0,
        premium: 0,
      },
    },
    {
      id: 'legal-egryul-changes',
      name: 'Документы для изменений в ЕГРЮЛ',
      cost: 5000,
      full: 'Подготовка документов для внесения изменений в сведения в ЕГРЮЛ о юридических лицах (смена директора, ОКВЭД, телефон, ошибки, без подачи документов в налоговую)',
      suggestion: 'Смена: директора, ОКВЭД, телефон, ошибки; без подачи документов в налоговую',
      costs: {
        standart: 7500,
        expert: 7500,
        business: 7500,
        premium: 0,
      },
    },
    {
      id: 'legal-documents-change',
      name: 'Изменение учредительных документов',
      cost: 5000,
      full: 'Подготовка документов по внесение изменений в учредительные документы смена адреса в 1 этап (без подачи документов в налоговую)',
      suggestion: 'смена адреса в 1 этап',
      costs: {
        standart: 7500,
        expert: 7500,
        business: 7500,
        premium: 0,
      },
    },
    {
      id: 'legal-address-change-stage-2',
      name: 'Смена адреса в 2 этапа',
      cost: 10000,
      full: 'Смена адреса в 2 этапа',
      costs: {
        standart: 15000,
        expert: 15000,
        business: 15000,
        premium: 0,
      },
    },
    {
      id: 'legal-capital-increase',
      name: 'Увеличение уставного капитала',
      cost: 10000,
      full: 'Увеличение уставного капитала',
      costs: {
        standart: 15000,
        expert: 15000,
        business: 15000,
        premium: 0,
      },
    },
    {
      id: 'legal-documents-check',
      name: 'Юр. проверка учредительных документов',
      cost: 5000,
      full: 'Юридическая проверка готовых учредительных документов клиента',
      costs: {
        standart: 7500,
        expert: 7500,
        business: 7500,
        premium: 0,
      },
    },
    {
      id: 'legal-organization-liquidation',
      name: 'Ликвидация организации',
      cost: 15000,
      suggestion:
        'Без процедуры банкротства, без подготовки ликвидационных балансов и без публикаций в Вестнике государственной регистрации',
      full: 'Ликвидация организации без процедуры банкротства, без подготовки ликвидационных балансов и публикаций в Вестнике государственной регистрации',
      costs: {
        standart: 22500,
        expert: 22500,
        business: 22500,
        premium: 0,
      },
    },
    {
      id: 'legal-egrip-changes',
      name: 'Документы для изменений в ЕГРИП',
      cost: 3000,
      suggestion:
        'Изменения сведений об ИП: паспортных данных, ОКВЭД; без подачи документов в налоговую',
      full: 'Подготовка документов по внесению изменений в сведения об индивидуальном предпринимателе в ЕГРИП (изменение паспортных данных, ОКВЭД) (без подачи документов в налоговую)',
      costs: {
        standart: 4500,
        expert: 4500,
        business: 4500,
        premium: 0,
      },
    },
    {
      id: 'legal-business-liquidation',
      name: 'Ликвидация предпринимательской деятельности',
      cost: 5000,
      suggestion: 'Без решения вопросов с СФР',
      full: 'Ликвидация предпринимательской деятельности без решения вопросов с СФР',
      costs: {
        standart: 7500,
        expert: 7500,
        business: 7500,
        premium: 0,
      },
    },
    {
      id: 'legal-case-analysis',
      name: 'Анализ судебного дела',
      cost: 10000,
      suggestion:
        'Изучение представленных материалов до 20 листов в общем объеме представленных документов',
      full: 'Анализ судебного дела (изучение представленных материалов до 20 листов в общем объеме представленных документов) ',
      costs: {
        standart: 15000,
        expert: 15000,
        business: 15000,
        premium: 0,
      },
    },
    {
      id: 'legal-documents',
      name: 'Правовые документы',
      cost: 5000,
      suggestion: 'Заявления, жалобы, претензии, ходатайства, без участия в судебном процессе',
      full: 'Составление заявлений, жалоб, претензий, ходатайств (без участия в судебном процессе)',
      costs: {
        standart: 7500,
        expert: 7500,
        business: 7500,
        premium: 0,
      },
    },
    {
      id: 'legal-power-of-attorney',
      name: 'Составление доверенностей',
      cost: 1000,
      suggestion: 'Для почты, налоговой, судов и др.',
      full: 'Составление доверенностей (для почты, налоговой, суды и др)',
      costs: {
        standart: 1500,
        expert: 1500,
        business: 0,
        premium: 0,
      },
    },
    {
      id: 'legal-order-open-branch',
      name: 'Приказ по открытию ОП',
      cost: 3000,
      full: 'Подготовка Приказа по открытию обособленного подразделения',
      costs: {
        standart: 4500,
        expert: 4500,
        business: 4500,
        premium: 0,
      },
    },
    {
      id: 'legal-order-close-branch',
      name: 'Приказ по закрытию ОП',
      cost: 3000,
      full: 'Подготовка Приказа по закрытию обособленного подразделения',
      costs: {
        standart: 4500,
        expert: 4500,
        business: 4500,
        premium: 0,
      },
    },
    {
      id: 'legal-analysis-local-acts',
      name: 'Юр. анализ локальных норм. актов',
      cost: 5000,
      full: 'Юридический анализ локальных нормативных актов (приказов, инструкций, положений)',
      suggestion: 'приказов, инструкций, положений',
      costs: {
        standart: 7500,
        expert: 7500,
        business: 7500,
        premium: 0,
      },
    },
    {
      id: 'legal-individual-documents',
      name: 'Индивидуальные документы',
      cost: 1000,
      full: 'Разработка иных индивидуальных документов по деятельности организации',
      costs: {
        standart: 1500,
        expert: 1500,
        business: 1500,
        premium: 0,
      },
    },
  ],
  'восстановление учета': [
    {
      id: 'legal-zero-reports-past-periods',
      name: 'Нулевые отчеты за прошлые периоды',
      cost: 1000,
      suggestion: 'Стоимость указана за один отчет',
      full: 'Нулевые отчеты за прошлые периоды\r\n*стоимость указана за один отчет',
      costs: {
        standart: 1500,
        expert: 1500,
        business: 1500,
        premium: 1500,
      },
    },
    {
      id: 'legal-tax-accounting-reporting',
      name: 'Подготовка налоговой и бухгалтерской отчетности',
      cost: 3000,
      suggestion:
        'На основании имеющихся данных Заказчика без анализа и восстановления; стоимость указана за один отчет',
      full: 'Подготовка налоговой и бухгалтерской отчетности на основании имеющихся данных Заказчика без анализа и восстановления\r\n*стоимость указана за один отчет',
      costs: {
        standart: 4500,
        expert: 4500,
        business: 4500,
        premium: 4500,
      },
    },
    {
      id: 'legal-labor-relations',
      name: 'Оформление трудовых отношений на предприятии',
      cost: 3000,
      suggestion:
        '(Подготовка полного пакета внутренних документов на 1 сотрудника, принятого в период, когда Заказчик не сопровождался у Исполнителя)',
      full: 'Оформление трудовых отношений на предприятии (подготовка полного пакета внутренних документов, необходимых для осуществления деятельности предприятия) на 1 сотрудника, принятого в период, когда Заказчик не сопровождался у Исполнителя',
      costs: {
        standart: 4500,
        expert: 4500,
        business: 4500,
        premium: 4500,
      },
    },
  ],
  'налоговый и бухучет': [
    {
      id: 'legal-express-audit-1c',
      name: 'Экспресс-аудит базы',
      suggestion: '1С: Бухгалтерия Предприятия 7.7, 8.2, 8.3',
      cost: 2000,
      full: 'Экспресс-аудит базы 1С: Бухгалтерия Предприятия 7.7, 8.2, 8.3',
      costs: {
        standart: 3000,
        expert: 3000,
        business: 3000,
        premium: 0,
      },
    },
    {
      id: 'legal-primary-documents-input',
      name: 'Обработка и ввод первичных документов',
      cost: 2000,
      suggestion:
        'После 10 числа месяца за предыдущий месяц обслуживания, следующего за отчетным периодом',
      full: 'Обработка и ввод первичных документов после 10 числа месяца за предыдущий месяц обслуживания, следующего за отчетным периодом',
      costs: {
        standart: 3000,
        expert: 3000,
        business: 3000,
        premium: 0,
      },
    },
    {
      divider: 'Корректировка или изменение сданной отчетности',
      suggestion: 'При изменении Заказчиком данных в документах или в учете',
    },
    {
      id: 'legal-vat-profit-quarter-2',
      name: 'за квартал по НДС и Прибыли',
      cost: 3000,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (за квартал по НДС и Прибыли) при изменении Заказчиком данных в документах или в учете\r\n* в зависимости от объема предоставленных документов может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 4500,
        expert: 4500,
        business: 4500,
        premium: 0,
      },
    },
    {
      id: 'legal-simplified-tax-system-2',
      name: 'УСН за налоговый период',
      cost: 5000,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (УСН за налоговый период) при изменении Заказчиком данных в документах или в учете\r\n* в зависимости от объема предоставленных документов может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 7500,
        expert: 7500,
        business: 7500,
        premium: 0,
      },
    },
    {
      id: 'legal-reports-assets-water-tax-1',
      name: 'в отчетах Имущество, водный налог',
      cost: 3000,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (в отчетах Имущество, водный налог) при изменении Заказчиком данных в документах или в учете\r\n* в зависимости от объема предоставленных документов может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 4500,
        expert: 4500,
        business: 4500,
        premium: 0,
      },
    },
    {
      id: 'legal-accounting-reports-1',
      name: 'бухгалтерской отчетности',
      cost: 3500,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (бухгалтерской отчетности) при изменении Заказчиком данных в документах или в учете\r\n* в зависимости от объема предоставленных документов может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 5250,
        expert: 5250,
        business: 5250,
        premium: 0,
      },
    },
    {
      divider: 'Корректировка сданной отчетности за период без ГБА',
      suggestion: 'Когда Заказчик не сопровождался в Главбух',
    },
    {
      id: 'legal-vat-profit-quarter-alt',
      name: 'за квартал по НДС и Прибыли',
      cost: 4000,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (за квартал по НДС и Прибыли) за период, когда Заказчик не сопровождался в Главбух Ассистент\r\n* в зависимости от объема предоставленных документов может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 6000,
        expert: 6000,
        business: 6000,
        premium: 0,
      },
    },
    {
      id: 'legal-simplified-tax-system-alt',
      name: 'УСН за налоговый период',
      cost: 5500,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (УСН за налоговый период) за период, когда Заказчик не сопровождался в Главбух Ассистент\r\n* в зависимости от объема предоставленных документов может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 8250,
        expert: 8250,
        business: 8250,
        premium: 0,
      },
    },
    {
      id: 'legal-reports-assets-water-tax-alt',
      name: 'в отчетах Имущество, Водный налог',
      cost: 3500,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (в отчетах Имущество, Водный налог) за период, когда Заказчик не сопровождался в Главбух Ассистент\r\n* в зависимости от объема предоставленных документов может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 5250,
        expert: 5250,
        business: 5250,
        premium: 0,
      },
    },
    {
      id: 'legal-accounting-reports-alt',
      name: 'бухгалтерской отчетности',
      cost: 3500,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (бухгалтерской отчетности) за период, когда Заказчик не сопровождался в Главбух Ассистент\r\n* в зависимости от объема предоставленных документов может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 5250,
        expert: 5250,
        business: 5250,
        premium: 0,
      },
    },
    {
      id: 'document-preparation',
      name: 'Подготовка документов, отчетов по данным и по нерегламентированной форме Заказчика',
      cost: 2500,
      full: 'Подготовка документов, отчетов по данным и по нерегламентированной форме Заказчика',
      costs: {
        standart: 3750,
        expert: 3750,
        business: 3750,
        premium: 0,
      },
    },
    {
      id: 'additional-account-maintenance',
      name: 'Ведение дополнительного р/с',
      cost: 1000,
      full: 'Ведение дополнительного расчетного счета',
      costs: {
        standart: 1500,
        expert: 1500,
        business: 1500,
        premium: 0,
      },
    },
    {
      id: 'accounting-for-branch',
      name: 'Ведение учета по ОП',
      cost: 4500,
      full: 'Ведение учета по обособленному подразделению',
      costs: {
        standart: 4500,
        expert: 4500,
        business: 4500,
        premium: 0,
      },
    },
    {
      id: 'statistical-reports',
      name: 'Отчеты в органы Статистики',
      cost: 1000,
      full: 'Подготовка отчетов в органы Статистики\r\n* зависимости от сложности отчета, может быть применен повышающий коэффициент от 2 до 7',
      costs: {
        standart: 1500,
        expert: 1500,
        business: 1500,
        premium: 0,
      },
    },
    {
      id: 'vat-refund-documents',
      name: 'Документы на возмещение НДС',
      cost: 30000,
      suggestion: 'из бюджета',
      full: 'Подготовка документов на возмещение НДС из бюджета',
      costs: {
        standart: 45000,
        expert: 45000,
        business: 45000,
        premium: 0,
      },
    },
    {
      id: 'report-6-ndfL',
      name: 'Отчеты 6-НДФЛ по ФЛ',
      cost: 0,
      suggestion: 'По которым заказчик является налоговым агентом',
      full: 'Подготовка отчетов 6-НДФЛ по физическим лицам (далее ФЛ), по которым Заказчик является налоговым агентом',
    },
    {
      id: 'responses-to-government-requests',
      name: 'Ответы на требования от гос органов',
      cost: 3000,
      maxCoef: 6,
      suggestion:
        'когда Заказчик не сопровождался в Главбух Ассистент, без сдачи корректирующей отчетности.',
      full: 'Подготовка ответов на Требования от государственных органов за период, когда Заказчик не сопровождался в Главбух Ассистент, без сдачи корректирующей отчетности\r\n* зависимости от объема запрашиваемой информации может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 4500,
        expert: 4500,
        business: 4500,
        premium: 0,
      },
    },
    {
      id: 'tax-optimization-consultation',
      name: 'Консультации по оптимизации налогообложения',
      cost: 10000,
      full: 'Консультации по вопросам оптимизации по налогообложению',
      costs: {
        standart: 15000,
        expert: 15000,
        business: 15000,
        premium: 15000,
      },
    },
    {
      id: 'managerial-accounting',
      name: 'Управленческий учет',
      suggestion: 'консультация, помощь в настройке, контроль данных',
      cost: 0,
      full: 'Управленческий учет (консультация, помощь в настройке, контроль данных)',
    },
    {
      id: 'foreign-economic-activity',
      name: 'ВЭД',
      cost: 0,
      tariff: 25,
      tariffs: {
        standart: 25,
        expert: 25,
        business: 25,
        premium: 25,
      },
      full: 'Внешнеэкономическая деятельность',
    },
    {
      id: 'non-server-accounting',
      name: 'Ведение учета в любых программах, размещенных не на сервере Исполнителя',
      cost: 0,
      tariff: 50,
      tariffs: {
        standart: 50,
        expert: 50,
        business: 50,
        premium: 50,
      },
      full: 'Ведение учета в любых программах, размещенных не на сервере Исполнителя',
    },
    {
      id: 'debt-collection',
      name: 'Сбор дебиторской задолженности',
      cost: 2.5,
      full: 'Сбор дебиторской задолженности',
      costs: {
        standart: 2.5,
        expert: 2.5,
        business: 2.5,
        premium: 0,
      },
    },
    {
      id: 'collection-of-primary-documents',
      name: 'Сбор первичных документов',
      cost: 2500,
      suggestion: 'с контрагентов',
      full: 'Сбор первичных документов с контрагентов',
      costs: {
        standart: 3750,
        expert: 0,
        business: 0,
        premium: 0,
      },
    },
  ],
  зиК: [
    {
      id: 'salary-recalculation',
      name: 'Повторный расчет заработной платы, аванса, отпуска, больничного, увольнения',
      cost: 900,
      suggestion: 'В связи с внесенными изменениями по запросу Заказчика',
      full: 'Повторный расчет заработной платы, аванса, отпуска, больничного, увольнения в связи с внесенными изменениями по запросу Заказчика',
      costs: {
        standart: 1350,
        expert: 1350,
        business: 1350,
        premium: 0,
      },
    },
    {
      id: 'report-correction',
      name: 'Корректировка сданной отчетности',
      cost: 5000,
      maxCoef: 6,
      suggestion: 'при изменении Заказчиком данных в документах или в учете',
      full: 'Корректировка сданной отчетности при изменении Заказчиком данных в документах или в учете\r\n* зависимости от объема запрашиваемой информации может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 7500,
        expert: 7500,
        business: 7500,
        premium: 0,
      },
    },
    {
      id: 'report-correction-no-support',
      name: 'Корректировка сданной отчетности',
      cost: 3000,
      maxCoef: 6,
      suggestion: 'за период, когда Заказчик не сопровождался у Исполнителя',
      full: 'Корректировка сданной отчетности за период, когда Заказчик не сопровождался у Исполнителя\r\n* в зависимости от объема запрашиваемой информации может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 4500,
        expert: 4500,
        business: 4500,
        premium: 0,
      },
    },
    {
      id: 'sfr-refund',
      name: 'Возмещение СФР по сотрудникам',
      cost: 0,
      tariff: 30,
      tariffs: {
        standart: 30,
        expert: 20,
        business: 0,
        premium: 0,
      },
      suggestion: 'которые трудоустроены в период, когда Заказчик не сопровождался у Исполнителя',
      full: 'Возмещение СФР по сотрудникам, которые трудоустроены в период, когда Заказчик не сопровождался у Исполнителя',
      costs: {
        standart: 0,
        expert: 0,
        business: 0,
        premium: 0,
      },
    },
    {
      id: 'employment-relationship',
      name: 'Оформление трудовых отношений на предприятии',
      cost: 3000,
      suggestion:
        '(Подготовка полного пакета внутренних документов, необходимых для осуществления деятельности предприятия) на 1 сотрудника, принятого в период, когда Заказчик не сопровождался у Исполнителя',
      full: 'Оформление трудовых отношений на предприятии (подготовка полного пакета внутренних документов, необходимых для осуществления деятельности предприятия) на 1 сотрудника, принятого в период, когда Заказчик не сопровождался у Исполнителя',
      costs: {
        standart: 4500,
        expert: 4500,
        business: 4500,
        premium: 0,
      },
    },
    {
      id: 'preparation-for-labor-inspection',
      name: 'Подготовка предприятия к проверке трудовой инспекцией',
      suggestion: 'анализ имеющихся документов, подготовка недостающих документов',
      cost: 10000,
      full: 'Подготовка предприятия к проверке трудовой инспекцией (анализ имеющихся документов, подготовка недостающих документов)',
      costs: {
        standart: 15000,
        expert: 15000,
        business: 15000,
        premium: 0,
      },
    },
    {
      id: 'government-requests-preparation',
      name: 'Подготовка ответов на требования от гос органов',
      cost: 2000,
      maxCoef: 6,
      suggestion:
        'когда Заказчик не сопровождался у Исполнителя, без сдачи корректирующей отчетности',
      full: 'Подготовка ответов на Требования от государственных органов за период, когда Заказчик не сопровождался у Исполнителя, без сдачи корректирующей отчетности * зависимости от объема запрашиваемой информации может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 3000,
        expert: 3000,
        business: 0,
        premium: 0,
      },
    },
    {
      id: 'staff-schedule-changes',
      name: 'Утверждение/внесение изменений в штатное расписание',
      cost: 4000,
      maxCoef: 6,
      suggestion:
        'когда Заказчик не сопровождался у Исполнителя, а так же в случае, если Заказчиком был утвержден проект штатного расписания и Исполнитель принял в работу',
      full: 'Утверждение/внесение изменений в штатное расписание за периоды, когда Заказчик не сопровождался у Исполнителя, а так же в случае, если Заказчиком был утвержден проект штатного расписания и Исполнитель принял в работу\r\n* в зависимости от объема запрашиваемой информации может быть применен повышающий коэффициент от 2 до 6\r\n**шаг – 1 000 ₽ за каждые 10 штатных должностей',
      costs: {
        standart: 6000,
        expert: 6000,
        business: 0,
        premium: 0,
      },
    },
    {
      id: 'job-descriptions',
      name: 'Должностные инструкции для сотрудников',
      cost: 2000,
      maxCoef: 6,
      suggestion: 'трудоустроенных до начала периода обслуживания у Исполнителя',
      full: 'Должностные инструкции для сотрудников, трудоустроенных до начала периода обслуживания у Исполнителя\r\n* в зависимости от объема запрашиваемой информации может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 3000,
        expert: 3000,
        business: 0,
        premium: 0,
      },
    },
    {
      id: 'additional-agreements',
      name: 'Дополнительные соглашения к трудовым договорам, приказы',
      cost: 500,
      suggestion: 'которые необходимо было оформить до начала периода обслуживания у Исполнителя',
      full: 'Дополнительные соглашения к трудовым договорам, приказы, которые необходимо было оформить до начала периода обслуживания у Исполнителя',
      costs: {
        standart: 750,
        expert: 750,
        business: 750,
        premium: 0,
      },
    },
    {
      id: 'employee-data-corrections',
      name: 'Корр-ки в личные данные сотрудников',
      cost: 0,
      suggestion:
        'если сотрудники были приняты до начала периода обслуживания (внесение детей, семейного положения, образование и т.д.) по инициативе Заказчика',
      full: 'Внесение корректировок в личные данные сотрудников, если сотрудники были приняты до начала периода обслуживания (внесение детей, семейного положения, образование и т.д.) по инициативе Заказчика *шаг – 500 руб. за каждые 5 сотрудников',
    },
    {
      id: 'document-check',
      name: 'Проверка кадровых документов',
      cost: 500,
      suggestion:
        'трудовые договоры, дополнительные соглашения, оформленные в периоды до начала обслуживания у Исполнителя',
      full: 'Проверка кадровых документов (трудовые договоры, дополнительные соглашения) оформленные в периоды до начала обслуживания у Исполнителя',
      costs: {
        standart: 750,
        expert: 750,
        business: 750,
        premium: 0,
      },
    },
    {
      id: 'regulatory-check',
      name: 'Проверка нормативно-правовых актов клиента',
      cost: 500,
      maxCoef: 6,
      suggestion:
        'Подготовленных самим клиентом до начала периода обслуживания у Исполнителя (Правила внутреннего трудового распорядка, Положение об оплате труда, Штатное расписание, Положение об отпусках, Положение о командировках, Положение о премировании и т.д.)',
      full: 'Проверка нормативно-правовых актов клиента, подготовленных самим клиентом до начала периода обслуживания у Исполнителя (Правила внутреннего трудового распорядка, Положение об оплате труда, Штатное расписание, Положение об отпусках, Положение о командировках, Положение о премировании и т.д.)\r\n* в зависимости от объема запрашиваемой информации может быть применен повышающий коэффициент от 2 до 6',
      costs: {
        standart: 750,
        expert: 750,
        business: 750,
        premium: 0,
      },
    },
    {
      id: 'military-registration',
      name: 'Воинский учет',
      cost: 1500,
      suggestion:
        'без личного присутствия в гос. учреждениях, только подготовка документов и инструктаж.',
      full: 'Воинский учет (без личного присутствия в гос. учреждениях, только подготовка документов и инструктаж).',
      costs: {
        standart: 2250,
        expert: 2250,
        business: 2250,
        premium: 0,
      },
    },
    {
      id: 'military-registration-docs',
      name: 'Документы для воинского учета',
      cost: 3500,
      full: 'Подготовка пакета документов для постановки на воинский учет',
      costs: {
        standart: 5250,
        expert: 5250,
        business: 5250,
        premium: 0,
      },
    },
    {
      id: 'migration-registration',
      name: 'Миграционный учет',
      cost: 10000,
      suggestion:
        'без личного присутствия в гос. учреждениях, только подготовка документов и инструктаж',
      full: 'Миграционный учет (без личного присутствия в гос. учреждениях, только подготовка документов и инструктаж).',
      costs: {
        standart: 15000,
        expert: 15000,
        business: 15000,
        premium: 0,
      },
    },
    {
      id: 'personnel-accounting',
      name: 'Учет расчетов с персоналом по ОП',
      cost: 1500,
      suggestion: 'в том числе на Тарифах "Зарплата и Кадры"',
      full: 'Учет расчетов с персоналом по обособленным подразделениям, в том числе на Тарифах "Зарплата и Кадры"',
      costs: {
        standart: 2250,
        expert: 2250,
        business: 2250,
        premium: 0,
      },
    },
  ],
};
